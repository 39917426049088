import { MdClose } from 'react-icons/md';

import { Avatar, Button, Flex, Icon, Text } from '@chakra-ui/react';

import { Directory } from 'src/shared/components/Directory/Directory';
import { useDirectoryUsers } from 'src/shared/hooks/useDirectory';
import { useSingleSearchParam } from 'src/shared/hooks/useSingleSearchParam';

export function SearchFilterExperts({
  experts,
}: {
  experts?: Record<string, number>;
}) {
  const [userId, setUserId] = useSingleSearchParam('expert');
  const sortedExperts = Object.entries(experts ?? {}).sort((a, b) => {
    return b[1] - a[1];
  });

  const users = useDirectoryUsers(sortedExperts.map(([user]) => user));
  const selUser = users.find((u) => u.id === userId);

  return (
    <>
      <Text fontSize="16px" fontWeight={700} color="#033426" mt="80px">
        Experts
      </Text>

      {/* Hide above 3 lines with a show more button */}

      <Flex flexWrap="wrap" mt={4}>
        {selUser && (
          <Button
            mr={8}
            mb={4}
            variant="link"
            size="sm"
            fontSize="13px"
            lineHeight="16px"
            fontWeight={700}
            borderRadius="20px"
            color="#033426"
            onClick={() => setUserId()}
            rightIcon={<Icon as={MdClose} />}
          >
            <Avatar size="sm" mr={2} src={selUser.image} />
            <span>
              <Directory userId={selUser.id} />
            </span>
          </Button>
        )}
        {!userId &&
          sortedExperts
            .filter(([user]) => user !== userId)
            .map(([user, count], i) => {
              if (!users[i]) return null;

              const image = users[i]?.image;
              return (
                <Button
                  key={user}
                  mr={6}
                  mb={4}
                  variant="link"
                  size="sm"
                  fontSize="13px"
                  lineHeight="16px"
                  fontWeight={400}
                  borderRadius="20px"
                  color="#033426"
                  onClick={() => setUserId(user)}
                >
                  <Avatar size="sm" mr={2} src={image} />
                  <span>
                    <Directory userId={user} /> ({count})
                  </span>
                </Button>
              );
            })}
      </Flex>
    </>
  );
}
