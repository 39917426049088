import { useNavigate } from 'react-router-dom';

import { Heading, Link, Text } from '@chakra-ui/react';

import { useDirectoryMe } from 'src/shared/hooks/useDirectory';

export function ExpertAccepted() {
  const me = useDirectoryMe();
  const navigate = useNavigate();
  if (!me.GS1PK) {
    navigate('/');
    return null;
  }
  return (
    <>
      <Heading size="md">
        Congrats! You&apos;re now a Knowledge Expert 🎉
      </Heading>
      <Text mt={10} fontWeight="bold">
        Key features:
      </Text>
      <Text mt={4} fontWeight="medium">
        📗
        <Link
          ml={2}
          href="https://www.questionbase.com/docs/how-does-question-base-work"
          color="blue.500"
        >
          Save Knowledge Directly from Chat
        </Link>
      </Text>
      <Text mt={3} fontWeight="medium">
        🔍 &nbsp;Pull answers from your existing documents
      </Text>
      <Text mt={3} fontWeight="medium">
        ✅ &nbsp;Auto-answer questions in #channels
      </Text>
    </>
  );
}
