import { useEffect, useState } from 'react';

import { Box, Button, Flex, Heading } from '@chakra-ui/react';
import { DBExtractTestCase } from '@core/types/types.db';
import {
  TesterExtractAddEndpoint,
  TesterExtractListEndpoint,
  TesterExtractRunEndpoint,
} from '@core/types/types.endpoint.tester';

import { useApi } from 'src/shared/hooks/shared.hook.useApi';
import { useInterval } from 'src/shared/hooks/shared.hook.useInterval';
import { useSubmitter } from 'src/shared/hooks/useSubmitter';
import { callApi } from 'src/shared/util/util.callApi';
import { TesterExtractItem } from 'src/Tester/Extract/TesterExtractItem';

export function TesterExtractPage() {
  const [lastCheckedAt, setLastCheckedAt] = useState<number | undefined>(
    undefined,
  );
  const [results, setResults] = useState<DBExtractTestCase[]>([]);
  const { data, refetch, loading } = useApi<TesterExtractListEndpoint>(
    '/tester/extract/list',
    {},
  );

  useEffect(() => {
    if (data) {
      setResults((prev) =>
        [
          ...prev.map((p) => {
            const d = data.test_cases.find((d) => d.ts === p.ts);
            return d || p;
          }),
          ...data.test_cases.filter((d) => !prev.some((p) => p.ts === d.ts)),
        ].sort((a, b) => a.ts.localeCompare(b.ts)),
      );
      setLastCheckedAt(data.last_checked_at);
    }
  }, [data]);

  useInterval(() => {
    refetch({ last_updated_at: lastCheckedAt });
  }, 5000);

  const runAll = useSubmitter(async (target: 'status_quo' | 'experiment') => {
    if (!data) return;

    await callApi<TesterExtractRunEndpoint>('/tester/extract/run', {
      ts: data.test_cases.map((d) => d.ts),
      target,
    });
  });

  const adder = useSubmitter(async () => {
    await callApi<TesterExtractAddEndpoint>('/tester/extract/add', {});
    refetch({ last_updated_at: lastCheckedAt });
  });

  return (
    <Box w="100%" margin="30px auto" maxW="1920px" p={2}>
      <Flex>
        <Heading
          size="md"
          display="flex"
          alignItems="center"
          w="40%"
          p={3}
          pl="60px"
          flex="none"
          maxW="720px"
        >
          Chat history
          <Button
            ml={4}
            colorScheme="messenger"
            size="sm"
            onClick={adder.submit}
            isLoading={loading || adder.isSubmitting}
          >
            Add
          </Button>
          <Button
            ml={4}
            colorScheme="messenger"
            size="sm"
            onClick={() => runAll.submit('status_quo')}
          >
            Run Left
          </Button>
          <Button
            ml={4}
            colorScheme="messenger"
            size="sm"
            onClick={() => runAll.submit('experiment')}
          >
            Run Right
          </Button>
        </Heading>
        <Heading size="md" w="100%" p={3}>
          Status quo
        </Heading>
        <Heading size="md" w="100%" p={3}>
          Results
        </Heading>
      </Flex>

      {results
        .map((d, i) => (
          <TesterExtractItem
            key={d.ts}
            item={d}
            index={i}
            onSave={(item) => {
              setResults(results.map((d) => (d.ts === item.ts ? item : d)));
            }}
          />
        ))
        .reverse()}
    </Box>
  );
}
