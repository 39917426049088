export function serviceIcon(baseUrl: string, service: string) {
  switch (service) {
    case 'questionbase':
      return `${baseUrl}/favicon.ico`;
    case 'INTERCOM':
      return `${baseUrl}/assets/services/intercom.png`;
    case 'NOTION':
      return `${baseUrl}/assets/services/notion.ico`;
    case 'GOOGLE_DRIVE':
      return `${baseUrl}/assets/services/gdrive.png`;
    case 'ZENDESK':
      return `${baseUrl}/assets/services/zendesk.ico`;
    case 'FRESHDESK':
      return `${baseUrl}/assets/services/freshdesk.ico`;
    case 'CONFLUENCE':
      return `${baseUrl}/assets/services/confluence.ico`;
    case 'GITHUB':
      return `${baseUrl}/assets/services/github.svg`;
    default:
      throw new Error(`Unknown service: ${service}`);
  }
}
