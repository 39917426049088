import { useRef } from 'react';

import { Center, Flex, Tag, TagCloseButton, TagLabel } from '@chakra-ui/react';

import {
  UserFieldExtensions,
  UserFieldInput,
} from 'src/shared/components/UserField/UserFieldInput';
import { useDirectoryUsers } from 'src/shared/hooks/useDirectory';

export interface UserFieldProps extends UserFieldExtensions {
  selectedIds: string[];
  onlyEditors?: boolean;
  setSelectedIds: (items: string[]) => void;
  forcedIds?: string[];
  includedIds?: string[];
  excludedIds?: string[];
  excludeDeleted?: boolean;
}

export const UserField = ({
  selectedIds,
  setSelectedIds,
  forcedIds,
  includedIds,
  excludedIds,
  placeholder,
  excludeDeleted,
  ...inputProps
}: UserFieldProps) => {
  const selectedUsers = useDirectoryUsers(selectedIds);
  const forcedUsers = useDirectoryUsers(forcedIds ?? []);
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Flex
      py={1}
      wrap="wrap"
      align="center"
      borderBottom="1px solid"
      borderColor="grey.4"
      width="100%"
      position="relative"
      _before={{
        content: '""',
        position: 'absolute',
        bottom: '0px',
        left: 0,
        right: 0,
        height: '1px',
        visibility: 'hidden',
        bg: 'grey.4',
      }}
      boxSizing="border-box"
      _focusWithin={{
        borderColor: 'blue.400',
        _before: {
          bg: 'blue.400',
          visibility: 'visible',
        },
      }}
      onClick={() => {
        inputRef.current?.focus();
      }}
    >
      {forcedUsers.map((item) => (
        <Center key={item?.id} h="8" mr={2}>
          <Tag size="md">
            <TagLabel>{item?.name}</TagLabel>
          </Tag>
        </Center>
      ))}
      {selectedUsers.map((item) => (
        <Center key={item?.id} h="8" mr={2}>
          <Tag size="md">
            <TagLabel>{item?.name}</TagLabel>
            <TagCloseButton
              isDisabled={inputProps.isDisabled}
              onClick={() =>
                setSelectedIds(selectedIds.filter((id) => id !== item?.id))
              }
            />
          </Tag>
        </Center>
      ))}

      <UserFieldInput
        ref={inputRef}
        includedIds={includedIds}
        excludedIds={excludedIds}
        excludeDeleted={excludeDeleted}
        {...inputProps}
        onSelectId={(id) => {
          setSelectedIds(selectedIds.concat([id]));
        }}
        onKeyDown={(e) => {
          if (
            e.key === 'Backspace' &&
            selectedIds.length &&
            !e.currentTarget.value
          ) {
            setSelectedIds(selectedIds.slice(0, -1));
          }
        }}
        selectedIds={selectedIds}
        placeholder={placeholder}
      />
    </Flex>
  );
};
