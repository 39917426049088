import { Skeleton, Stack } from '@chakra-ui/react';

export function SearchEmptyLoading() {
  return (
    <Stack spacing={2} mt={6}>
      <Skeleton height="52px" borderRadius={8} />
      <Skeleton height="52px" borderRadius={8} />
      <Skeleton height="52px" borderRadius={8} />
      <Skeleton height="52px" w="90%" borderRadius={8} />
    </Stack>
  );
}
