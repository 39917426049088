import { useState } from 'react';
import { IoIosCheckmark } from 'react-icons/io';

import {
  Box,
  Button,
  Checkbox,
  Flex,
  Icon,
  OrderedList,
  Spacer,
  Spinner,
  Text,
} from '@chakra-ui/react';
import {
  DirectoryChannelsEndpoint,
  DirectorySetupEndpoint,
} from '@core/types/types.endpoint.directory';

import { AddBot } from 'src/App/App.AddBot';
import { ListEl } from 'src/App/App.ListEl';
import { UserField } from 'src/shared/components/UserField/UserField';
import { useApi } from 'src/shared/hooks/shared.hook.useApi';
import { useDirectoryUpdater } from 'src/shared/hooks/shared.hook.useDirectoryUpdater';
import {
  useDirectoryMe,
  useDirectoryTeam,
} from 'src/shared/hooks/useDirectory';
import { useSubmitter } from 'src/shared/hooks/useSubmitter';
import { callApi } from 'src/shared/util/util.callApi';

export function AppSetup() {
  const updater = useDirectoryUpdater();
  const me = useDirectoryMe();
  const team = useDirectoryTeam();
  const isInstalled = !!team.slack_bot_user_id;
  const canAddChannels = !!team.slack_scope?.includes('channels:join');

  const [selectedUsers, setSelectedUsers] = useState<string[]>([me.id]);
  const [selectedChannels, setSelectedChannels] = useState<string[]>([]);
  const [addTestChannel, setAddTestChannel] = useState(!canAddChannels);

  const { data: channels, loading } = useApi<DirectoryChannelsEndpoint>(
    '/directory/channels',
    {},
    isInstalled && canAddChannels,
  );

  const submit = useSubmitter(async () => {
    const [, res] = await callApi<DirectorySetupEndpoint>('/directory/setup', {
      addTestChannel,
      channelIds: selectedChannels,
      expertIds: selectedUsers,
    });

    if (res) {
      updater({ team: res });
    }
  });

  return (
    <>
      <OrderedList>
        <ListEl title="Add the bot to your Slack Workspace">
          <Spacer height={6} />
          {isInstalled ? (
            <Flex alignItems="center" pb={10}>
              <Icon
                as={IoIosCheckmark}
                h={6}
                background="rgba(176, 248, 120, 1)"
                borderRadius={12}
                w={6}
                color="rgba(27, 27, 27, 1)"
              />
              <Text ml={3} color="rgba(27, 27, 27, 1)">
                Bot successfully installed!
              </Text>
            </Flex>
          ) : (
            <AddBot />
          )}
        </ListEl>
        <ListEl
          opacity={isInstalled ? 1 : 0.3}
          title="Who keeps documentation up-to-date?"
        >
          <Spacer height={5} />
          <UserField
            isDisabled={!isInstalled}
            placeholder="Select users"
            excludeDeleted
            selectedIds={selectedUsers}
            setSelectedIds={setSelectedUsers}
          />
          <Spacer height={5} />
        </ListEl>
        {canAddChannels && (
          <ListEl
            opacity={isInstalled ? 1 : 0.3}
            title="Select channels for the bot to join"
          >
            <Box mt={6} mb={6}>
              <Checkbox
                isChecked={addTestChannel}
                onChange={(e) => {
                  setAddTestChannel(e.target.checked);
                }}
              >
                Create #questionbase-testing
              </Checkbox>
            </Box>

            {loading && <Spinner />}
            {channels?.map((c) => (
              <Box key={c.id} mt={3}>
                <Checkbox
                  isChecked={selectedChannels.includes(c.id)}
                  onChange={(e) => {
                    // If the option is already selected, remove it from the array
                    // Otherwise, add it to the array
                    setSelectedChannels(
                      e.target.checked
                        ? [...selectedChannels, c.id]
                        : selectedChannels.filter((id) => id !== c.id),
                    );
                  }}
                >
                  #{c.name}
                </Checkbox>
              </Box>
            ))}
          </ListEl>
        )}
      </OrderedList>
      <Button
        mt={4}
        colorScheme="action"
        ml={4}
        onClick={submit.submit}
        isLoading={submit.isSubmitting}
        isDisabled={
          !selectedUsers.length ||
          !isInstalled ||
          (!addTestChannel && !selectedChannels.length)
        }
      >
        Setup
      </Button>
    </>
  );
}
