import { LuCopy } from 'react-icons/lu';

import {
  Center,
  Flex,
  Heading,
  Icon,
  IconButton,
  Text,
  Tooltip,
} from '@chakra-ui/react';

export function AddToChannel() {
  return (
    <Center display="flex" flexDirection="column">
      <Heading size="sm" mt={10}>
        Final step:
      </Heading>
      <Text textAlign="center" mt={2} color="gray.600">
        Add the bot to active channels
      </Text>
      <Flex alignItems="center" mt={6}>
        <Text fontWeight={700}>Open a channel in Slack and write →</Text>
        <Flex
          alignItems="center"
          pl={4}
          ml={4}
          pr={2}
          py={1}
          border="1px solid"
          borderColor="action.200"
          borderRadius={10}
        >
          <Text fontWeight={500}>/invite @questionbase</Text>
          {/** Copy url to clipboard button */}
          <Tooltip label="Copy command to clipboard" placement="top">
            <IconButton
              size="sm"
              ml={2}
              colorScheme="action"
              variant="ghost"
              onClick={() =>
                navigator.clipboard.writeText(`/invite @questionbase`)
              }
              icon={<Icon as={LuCopy} />}
              aria-label="Copy command to clipboard"
            />
          </Tooltip>
        </Flex>
      </Flex>
      <Text textAlign="center" mt={8} color="gray.600">
        And that&apos;s it! 👌 You&apos;re all set up.
      </Text>
      {/** Button to reload the page */}
    </Center>
  );
}
