import { useEffect, useRef, useState } from 'react';

import { EndpointError } from '@core/types/types.endpoint';
import { QuestionSearchEndpoint } from '@core/types/types.endpoint.question';

import { callApi } from 'src/shared/util/util.callApi';

export interface QuestionSearchParams {
  query: string;
}

export function useESQuestionSearch({ query }: QuestionSearchParams) {
  const [result, setResult] = useState<
    QuestionSearchEndpoint['response'] | null
  >(null);
  const [error, setError] = useState<EndpointError | null>(null);
  const [loading, setLoading] = useState(!!query);

  const searchRef = useRef('');

  useEffect(() => {
    if (!query || searchRef.current === query) return;

    searchRef.current = query;
    setLoading(() => true);

    callApi<QuestionSearchEndpoint>('/question/search', {
      query,
    }).then(([err, res]) => {
      if (searchRef.current === query) {
        setResult(res);
        setError(err);
        setLoading(false);
      }
    });
  }, [loading, query]);

  return {
    res: 'search' as const,
    loading,
    error,
    result,
  };
}
