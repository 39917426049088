import { useEffect } from 'react';
import { BsFillLightningChargeFill } from 'react-icons/bs';
import { FaHashtag, FaLock, FaSlack } from 'react-icons/fa';

import {
  Button,
  Heading,
  Icon,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { DirectoryAnalyticsEndpoint } from '@core/types/types.endpoint.directory';
import {
  IntegrationAuthEndpoint,
  IntegrationUsageEndpoint,
} from '@core/types/types.endpoint.integration';
import {
  CarbonConnect,
  EmbeddingGenerators,
  IntegrationName,
} from 'carbon-connect';

import { SettingsFeatureFlag } from 'src/Settings/SettingsFeatureFlag';
import { useApi } from 'src/shared/hooks/shared.hook.useApi';
import {
  useDirectoryChannels,
  useDirectoryTeam,
} from 'src/shared/hooks/useDirectory';
import { callApi } from 'src/shared/util/util.callApi';

export function SettingsPage() {
  const team = useDirectoryTeam();
  const channels = useDirectoryChannels({
    excludeDeleted: true,
    requireQbInChannel: true,
  });
  const { data, error, loading } = useApi<IntegrationUsageEndpoint>(
    '/integration/usage',
    {},
  );

  const isPro = team.feature_flags?.integrations;

  const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(() => {
    if (isOpen) {
      callApi<DirectoryAnalyticsEndpoint>('/directory/analytics', {
        event: 'Viewed Integrations',
      });
    }
  }, [isOpen]);

  const tokenFetcher = async () => {
    const [err, res] = await callApi<IntegrationAuthEndpoint>(
      '/integration/auth',
      {},
    );

    if (err || !res) {
      throw new Error('Failed to fetch token');
    }

    return res;
  };

  let label = '';
  if (loading) {
    label = 'Loading...';
  } else if (!error && !data?.connections.length) {
    label = 'No integrations connected yet';
  }

  return (
    <>
      <Heading size="md" mt={6}>
        Knowledge Sources
      </Heading>

      <Text mt={2}>Connected accounts: {label}</Text>

      {!!data?.connections.length &&
        data?.connections.map(({ icon, service }, i) => (
          <Text mt={2} key={i} display="flex" alignItems="center">
            <Image src={icon} w={6} h={6} mr={2} />
            {service}
          </Text>
        ))}

      <Button
        leftIcon={<Icon as={BsFillLightningChargeFill} />}
        colorScheme="action"
        mt={3}
        size="sm"
        onClick={async () => {
          onOpen();
        }}
      >
        Manage Integrations
      </Button>
      <Text mt={2} fontWeight={500} color="gray.500" fontSize="xs">
        Include search results from your favorite tools{' '}
        <Button
          as="a"
          variant="link"
          colorScheme="blue"
          fontSize="xs"
          cursor="pointer"
          target="_blank"
          fontWeight={700}
          href="https://www.questionbase.com/docs/how-to-integrate-question-base-with-your-favorite-tools"
        >
          Learn more
        </Button>
      </Text>
      {!isPro && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Pro feature</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text fontWeight={700}>
                You can test our integrations for free.{' '}
                <Link
                  textDecoration="underline"
                  href="https://www.questionbase.com/pricing"
                  isExternal
                  _hover={{ textDecoration: 'none' }}
                >
                  See pricing
                </Link>
              </Text>
              <Text mt={2}>
                Book a call with{' '}
                <Link
                  fontWeight={500}
                  color="blue"
                  href="https://www.linkedin.com/in/ktornoe/"
                  textDecoration="underline"
                  _hover={{ textDecoration: 'none' }}
                >
                  Kasper
                </Link>{' '}
                from our team to get started.
              </Text>

              <Button
                mt={6}
                as="a"
                target="_blank"
                href="https://cal.com/tornoe/25"
                colorScheme="action"
              >
                Book a call
              </Button>
              <Text fontWeight={500} mt={8} color="gray.600">
                Include search results from your favorite tools.
                <br />
                We currently support:
              </Text>
              <Text mt={2} color="gray.600">
                • Notion
              </Text>
              <Text color="gray.600">• Confluence</Text>
              <Text color="gray.600">• Guru</Text>
              <Text color="gray.600">• Zendesk</Text>
              <Text color="gray.600">• Intercom</Text>
              <Text color="gray.600">• Freshdesk</Text>
              <Text color="gray.600">• Github</Text>
              <Text color="gray.600">• Salesforce</Text>
              <Text color="gray.600">• Box</Text>
              <Text color="gray.600">• Dropbox</Text>
              <Text color="gray.600">• Google</Text>
              <Text color="gray.600">• Drive</Text>
            </ModalBody>
            <ModalFooter />
          </ModalContent>
        </Modal>
      )}
      {isPro && (
        <CarbonConnect
          orgName="Question Base"
          brandIcon="/assets/logoimage.png"
          embeddingModel={EmbeddingGenerators.COHERE_MULTILINGUAL_V3}
          tokenFetcher={tokenFetcher}
          setOpen={(_isOpen) => {
            if (_isOpen) {
              onOpen();
            } else {
              onClose();
            }
          }}
          open={isOpen}
          environment="PRODUCTION"
          enabledIntegrations={[
            {
              id: IntegrationName.NOTION,
              syncFilesOnConnection: false,
              enableAutoSync: true,
              incrementalSync: true,
            },
            {
              id: IntegrationName.ZENDESK,
              syncFilesOnConnection: false,
              enableAutoSync: true,
              useCarbonFilePicker: true,
            },
            {
              id: IntegrationName.FRESHDESK,
              syncFilesOnConnection: false,
              enableAutoSync: false,
              useCarbonFilePicker: true,
            },
            {
              id: IntegrationName.INTERCOM,
              syncFilesOnConnection: false,
              enableAutoSync: false,
              useCarbonFilePicker: true,
            },
            {
              id: IntegrationName.GURU,
              syncFilesOnConnection: false,
              enableAutoSync: false,
              useCarbonFilePicker: true,
            },
            {
              id: IntegrationName.CONFLUENCE,
              syncFilesOnConnection: false,
              enableAutoSync: false,
              useCarbonFilePicker: true,
            },
            {
              id: IntegrationName.GOOGLE_DRIVE,
              syncFilesOnConnection: false,
              enableAutoSync: true,
              incrementalSync: true,
            },
            {
              id: IntegrationName.DROPBOX,
              syncFilesOnConnection: false,
              enableAutoSync: false,
              useCarbonFilePicker: true,
            },
            {
              id: IntegrationName.BOX,
              syncFilesOnConnection: false,
              enableAutoSync: false,
              useCarbonFilePicker: true,
            },
            {
              id: IntegrationName.SALESFORCE,
              syncFilesOnConnection: false,
              enableAutoSync: false,
              useCarbonFilePicker: true,
            },
            {
              id: IntegrationName.GITHUB,
              syncFilesOnConnection: false,
              enableAutoSync: false,
              useCarbonFilePicker: true,
            },
          ]}
          chunkSize={1500}
          overlapSize={20}
        ></CarbonConnect>
      )}
      <Heading size="md" mt={16}>
        Slack Channels
      </Heading>
      {channels.map((channel) => (
        <Text key={channel.id} display="flex" alignItems="center" mt={2}>
          <Icon as={channel.is_private ? FaLock : FaHashtag} mr={2} />{' '}
          {channel.name}
        </Text>
      ))}
      <Button
        leftIcon={<Icon as={FaSlack} />}
        colorScheme="action"
        as="a"
        href="https://www.questionbase.com/docs/invite-to-channels"
        mt={4}
        size="sm"
        target="_blank"
        rel="noopener noreferrer"
      >
        Add to channels
      </Button>
      <Text mt={2} fontWeight={500} color="gray.500" fontSize="xs">
        Answer & capture automatically from Slack{' '}
        <Button
          as="a"
          variant="link"
          colorScheme="blue"
          fontSize="xs"
          cursor="pointer"
          target="_blank"
          fontWeight={700}
          href="https://www.questionbase.com/docs/invite-to-channels"
        >
          Learn more
        </Button>
      </Text>
      <Heading size="md" mt={12}>
        Features
      </Heading>
      <SettingsFeatureFlag
        feature={'ai_answer'}
        title="AI Answer"
        description="Generate answer using AI"
      />
      <SettingsFeatureFlag
        feature={'declarative_response'}
        title="Document Knowledge Share"
        description="Help with documenting when people share knowledge in Slack"
      />
    </>
  );
}
