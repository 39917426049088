import { HiExternalLink } from 'react-icons/hi';

import { Icon, Img, Link, Text } from '@chakra-ui/react';
import { AutoAnswer } from '@core/types/types.db';
import { serviceIcon } from '@core/util/util.serviceIcon';

export function SearchResultLink({ answer }: { answer: AutoAnswer }) {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    window.open(answer.url, '_blank', 'noopener,noreferrer');
  };

  return (
    <Link
      mt={1.5}
      variant="unstyled"
      cursor={'pointer'}
      px={5}
      _hover={{
        background: '#F0F8F5',
        border: '1px solid rgba(3, 52, 38, 0.5)',
      }}
      border="1px solid rgba(3, 52, 38, 0.2)"
      borderRadius="8px"
      background="#ffffff"
      href={answer.url}
      onClick={handleClick}
      display="flex"
      alignItems="center"
    >
      <Img src={serviceIcon('', answer.service)} width="32px" height="32px" />
      <Text
        fontWeight={500}
        fontSize="15px"
        lineHeight="20px"
        color="#033426"
        letterSpacing="-0.01em"
        pr={2}
        ml={4}
        py={5}
      >
        {answer.title}
      </Text>
      <Icon
        as={HiExternalLink}
        ml="auto"
        mr={1.5}
        flex="none"
        width="20px"
        height="20px"
        color="gray.400"
      />
    </Link>
  );
}
