import { forwardRef, useMemo, useState } from 'react';

import {
  Avatar,
  Box,
  Center,
  Flex,
  Input,
  InputGroup,
  InputProps,
} from '@chakra-ui/react';
import { useCombobox } from 'downshift';
import Fuse from 'fuse.js';

import { useDirectoryUsers } from 'src/shared/hooks/useDirectory';

export type UserFieldExtensions = Pick<
  InputProps,
  'placeholder' | 'onKeyDown' | 'isDisabled'
>;

export interface UserFieldInputProps extends UserFieldExtensions {
  onSelectId: (id: string) => void;
  selectedIds: string[];
  includedIds?: string[];
  excludedIds?: string[];
  onlyEditors?: boolean;
  excludeDeleted?: boolean;
}

type ShareItem = {
  id: string;
  name: string;
  image: string;
};

export const UserFieldInput = forwardRef<HTMLInputElement, UserFieldInputProps>(
  function UserFieldInput(
    {
      onSelectId,
      selectedIds,
      excludedIds,
      includedIds,
      // onlyEditors,
      excludeDeleted,
      ...inputProps
    },
    ref,
  ) {
    const _rawUsers = useDirectoryUsers();
    const [query, setQuery] = useState('');

    const users = useMemo(
      () =>
        _rawUsers
          .filter((i) => {
            // if (onlyEditors && i.plan !== 'editor') {
            //   return false;
            // }
            if (excludedIds && excludedIds.includes(i.id)) {
              return false;
            }
            if (includedIds && !includedIds.includes(i.id)) {
              return false;
            }
            if (excludeDeleted && i.is_slack_deleted) {
              return false;
            }
            return true;
          })
          .map(
            (user) =>
              ({
                id: user.id,
                name: user.name,
                image: user.image,
              }) as ShareItem,
          ),
      [_rawUsers, excludedIds, includedIds],
    );

    const searchUsers = useMemo(
      () =>
        new Fuse(users, {
          keys: ['name'],
        }),
      [users],
    );

    const inputItems = useMemo(() => {
      if (query) {
        return searchUsers
          .search(query)
          .map((item) => item.item)
          .filter((item) => !selectedIds.find((id) => id === item.id));
      }

      return users.filter(
        (item: ShareItem) => !selectedIds.find((id) => id === item.id),
      );
    }, [users, selectedIds, query, searchUsers]);

    const ds = useCombobox({
      defaultHighlightedIndex: 0,
      items: inputItems,
      selectedItem: null,
      onSelectedItemChange: ({ selectedItem }) => {
        if (selectedItem) {
          onSelectId(selectedItem.id);
          ds.setInputValue('');
        }
      },
      onInputValueChange: ({ inputValue = '' }) => {
        if (typeof inputValue === 'string') {
          setQuery(inputValue);
        }
      },
    });

    return (
      <Center position="relative" h={8} w="240px">
        <InputGroup size="sm">
          <Input
            size="md"
            variant="unstyled"
            {...ds.getInputProps({ ref, ...inputProps })}
          />
        </InputGroup>
        <Box
          as={'ul'}
          {...ds.getMenuProps()}
          position="absolute"
          top="100%"
          left={0}
          right={0}
          zIndex={1}
          bg="white"
          border="1px solid"
          borderColor="grey.3"
          visibility={ds.isOpen && !!inputItems.length ? 'visible' : 'hidden'}
          overflowY="auto"
          maxH="210px"
        >
          {ds.isOpen &&
            inputItems.map((item, index) => (
              <Flex
                p={1}
                align="center"
                bg={ds.highlightedIndex === index ? 'blue.100' : 'white'}
                key={item.id}
                {...ds.getItemProps({ item, index })}
              >
                <Avatar src={item.image} mr={2} h="24px" w="24px" />
                {item.name}
              </Flex>
            ))}
        </Box>
      </Center>
    );
  },
);
