import { LuCopy, LuTrash2 } from 'react-icons/lu';
import { TbDotsVertical } from 'react-icons/tb';

import {
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';

import { useDeleter } from 'src/shared/components/Question/hooks/Question.hooks.useDeleter';
import { QuestionContext } from 'src/shared/components/Question/QuestionContext';
import { useIsEditor } from 'src/shared/hooks/shared.hook.useIsEditor';
import { useDirectoryTeam } from 'src/shared/hooks/useDirectory';
import { useRequiredContext } from 'src/shared/hooks/useRequiredContext';

export function QuestionActions({ onEdit }: { onEdit: () => void }) {
  const team = useDirectoryTeam();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const isEditor = useIsEditor();

  const editHandler = () => {
    onEdit();
    onClose();
  };

  const question = useRequiredContext(QuestionContext);

  const { isDeleting, onConfirmDelete, confirmer } = useDeleter(question.id);

  const visibleUrl = `${window.location.host}/q/${question.id}`;
  const url = `${window.location.origin}/q/${question.id}?team=${team.id}`;

  return (
    <Popover isOpen={isOpen} onClose={onClose} computePositionOnMount>
      <PopoverTrigger>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onOpen();
          }}
          isLoading={isDeleting}
          ml="auto"
          width="32px"
          flex="none"
          pr={0}
          height="32px"
          size="sm"
          variant="ghost"
          aria-label="Learn more"
          icon={<Icon as={TbDotsVertical} />}
        />
      </PopoverTrigger>
      <PopoverContent w="sm">
        <PopoverHeader
          borderBottom="1px solid rgba(3, 52, 38, 0.1)"
          background="rgba(3, 52, 38, 0.03)"
          color="rgba(3, 52, 38, 1)"
          letterSpacing="-0.01em"
          fontWeight={500}
          p={4}
          height="56px"
          fontSize="16px"
          position="relative"
          lineHeight={1.5}
        >
          Details
          <PopoverCloseButton
            top="50%"
            transform="translateY(-50%)"
            right={4}
          />
        </PopoverHeader>
        <PopoverBody px={8} py={6}>
          <Heading
            fontSize="13px"
            lineHeight={1.5}
            fontWeight={500}
            color="action.600"
            letterSpacing="-0.01em"
          >
            Views
          </Heading>
          <Text
            fontSize="48px"
            lineHeight={1}
            fontWeight={500}
            color="action.900"
            letterSpacing="-0.06em"
            mt={2}
          >
            {question.view_count}
          </Text>

          <Heading
            fontSize="13px"
            lineHeight={1.5}
            fontWeight={500}
            color="action.600"
            letterSpacing="-0.01em"
            mt={6}
          >
            Share this answer
          </Heading>
          <Flex>
            <Button
              as="a"
              href={url}
              target="_blank"
              variant="link"
              colorScheme="action"
              size="sm"
              color="action.800"
              mr={1}
            >
              {visibleUrl}
            </Button>
            {/** Copy url to clipboard button */}
            <Tooltip label="Copy URL to clipboard" placement="top">
              <IconButton
                size="sm"
                colorScheme="action"
                variant="ghost"
                onClick={() => navigator.clipboard.writeText(url)}
                icon={<Icon as={LuCopy} />}
                aria-label="Copy URL to clipboard"
              />
            </Tooltip>
          </Flex>
        </PopoverBody>
        {isEditor && (
          <PopoverFooter mt={5}>
            <Flex alignItems="center">
              {confirmer}
              <Tooltip label="Edit question">
                <Button
                  colorScheme="action"
                  variant="solid"
                  size="sm"
                  onClick={editHandler}
                >
                  Edit
                </Button>
              </Tooltip>

              <Tooltip label="Delete question">
                <IconButton
                  ml="auto"
                  aria-label="Delete question"
                  variant="solid"
                  colorScheme="error"
                  size="sm"
                  onClick={onConfirmDelete}
                  icon={<Icon as={LuTrash2} />}
                />
              </Tooltip>
            </Flex>
          </PopoverFooter>
        )}
      </PopoverContent>
    </Popover>
  );
}
