import { Box, Link, Text } from '@chakra-ui/react';

import { useDirectoryChannels } from 'src/shared/hooks/useDirectory';

export function SearchEmpty({ hasFilter }: { hasFilter: boolean }) {
  if (hasFilter) {
    return (
      <Box textAlign="center" pt="200px">
        <Text
          color="action.400"
          fontWeight={700}
          fontSize="32px"
          lineHeight="35px"
        >
          No results
        </Text>
      </Box>
    );
  }

  const channels = useDirectoryChannels({
    requireQbInChannel: true,
    excludeDeleted: true,
  });

  return (
    <Box textAlign="center" pt="200px">
      <Text fontWeight={700} fontSize="18px">
        No saved answers yet.
      </Text>
      <Text mt={4}>
        Go to Slack and share some know-how
        <br />
        or ask a question in one of these channels.
      </Text>
      <Text mt={5}>
        {channels.map((c) => (
          <Link
            display="block"
            key={c.id}
            href={`https://slack.com/app_redirect?channel=${c.id}`}
            target="_blank"
            rel="noopener noreferrer"
            color="blue"
            mt={2}
          >
            #{c.name}
          </Link>
        ))}
      </Text>
    </Box>
  );
}
