import { IoIosArrowBack } from 'react-icons/io';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { Flex, IconButton, Text } from '@chakra-ui/react';

import { AppTopbarAddQuestion } from 'src/App/Topbar/App.Topbar.AddQuestion';
import { useDirectoryTeam } from 'src/shared/hooks/useDirectory';

export function Topbar() {
  const team = useDirectoryTeam();
  const { pathname } = useLocation();
  const isSearchPage = pathname.startsWith('/questions');
  const isSettingsPage = pathname.startsWith('/settings');

  return (
    <Flex py={8} alignItems="center">
      {isSettingsPage && (
        <IconButton
          as={Link}
          to="/"
          colorScheme="action"
          variant="ghost"
          mr={2}
          icon={<IoIosArrowBack size={24} />}
          aria-label="Back"
        />
      )}
      <Text
        fontSize="28px"
        lineHeight="40px"
        fontWeight={600}
        letterSpacing="-0.01em"
        mr="auto"
        color="#033426"
      >
        {isSettingsPage ? 'Settings' : team?.title}
      </Text>
      {isSearchPage && <AppTopbarAddQuestion />}
    </Flex>
  );
}
