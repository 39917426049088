import { Link } from 'react-router-dom';

import { Button, Center, Flex, Heading, Text } from '@chakra-ui/react';

import { AddToChannel } from 'src/App/App.AddToChannel';
import { useDirectoryTeam } from 'src/shared/hooks/useDirectory';

export function AppOnboardingSuccess() {
  const team = useDirectoryTeam();

  const canAddChannels = !!team.slack_scope?.includes('channels:join');

  if (!canAddChannels) {
    return <AddToChannel />;
  }

  return (
    <Center display="flex" flexDirection="column">
      <Heading size="sm">That&apos;s it! 👌 You&apos;re all set up.</Heading>
      <Text textAlign="center" mt={8} color="gray.600">
        You can now start using Question Base in Slack.
      </Text>
      <Flex mt={8}>
        <Button
          as="a"
          size="sm"
          colorScheme="action"
          href={`slack://open?team=${team.id}`}
          target="_blank"
        >
          Open Slack
        </Button>
        <Button size="sm" colorScheme="action" ml={4} as={Link} to="/settings">
          Open Settings
        </Button>
      </Flex>
    </Center>
  );
}
