import { Box, Button, Flex, Heading, Switch, Text } from '@chakra-ui/react';
import { DirectoryFeatureEndpoint } from '@core/types/types.endpoint.directory';
import { Features } from '@core/types/types.features';

import { useBroadcaster } from 'src/shared/hooks/shared.hook.useBroadcaster';
import { DirectoryContext } from 'src/shared/hooks/useDirectory';
import { useRequiredContext } from 'src/shared/hooks/useRequiredContext';
import { useSubmitter } from 'src/shared/hooks/useSubmitter';
import { callApi } from 'src/shared/util/util.callApi';

export interface SettingsFeatureFlag {
  title: string;
  description: string;
  learnMore?: string;
  feature: Features;
}

export function SettingsFeatureFlag({
  title,
  description,
  learnMore,
  feature,
}: SettingsFeatureFlag) {
  const broadcast = useBroadcaster();
  const directory = useRequiredContext(DirectoryContext);

  const isChecked = directory.team.feature_flags?.[feature] ?? false;
  const submitter = useSubmitter(async () => {
    const [, res] = await callApi<DirectoryFeatureEndpoint>(
      `/directory/feature`,
      {
        feature,
        enabled: !isChecked,
      },
    );
    if (res) {
      directory.team = res;
      broadcast({ event: 'directory:save', directory });
    }
  });
  return (
    <Flex alignItems="center" mt={3}>
      <Switch
        isDisabled={submitter.isSubmitting}
        isChecked={isChecked}
        onChange={() => {
          submitter.submit();
        }}
      />
      <Box ml={4}>
        <Heading size="xs">{title}</Heading>

        <Text mt={1} fontWeight={500} color="gray.500" fontSize="xs">
          {description}
          {learnMore && (
            <Button
              as="a"
              ml={1}
              variant="link"
              colorScheme="blue"
              fontSize="xs"
              cursor="pointer"
              target="_blank"
              fontWeight={700}
              href={learnMore}
            >
              Learn more
            </Button>
          )}
        </Text>
      </Box>
    </Flex>
  );
}
