import { useState } from 'react';

import { SearchEmpty } from 'src/Search/Empty/SearchEmpty';
import { SearchInput } from 'src/Search/Input/SearchInput';
import { useQuestionFilter } from 'src/Search/Search.useQuestionFilter';
import { SearchBrowse } from 'src/Search/SearchBrowse';
import { SearchResults } from 'src/Search/SearchResults';
import { useSingleSearchParam } from 'src/shared/hooks/useSingleSearchParam';

export function SearchPage() {
  const [q, setQ] = useSingleSearchParam('q');
  const { result, hasFilter } = useQuestionFilter();

  const [value, onChange] = useState(q);
  const { loading } = result;

  if (
    result.res === 'browse' &&
    !hasFilter &&
    !result.loading &&
    !result.result?.questions.length
  ) {
    return <SearchEmpty hasFilter={false} />;
  }

  return (
    <>
      <SearchInput
        placeholder="Ask a question..."
        searching={!!q && loading}
        searchVal={q}
        value={value}
        onChange={onChange}
        onSearch={setQ}
      />
      {result.res === 'browse' && (
        <SearchBrowse res={result} hasFilter={hasFilter} />
      )}
      {result.res === 'search' && <SearchResults res={result} />}
    </>
  );
}
