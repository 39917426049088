import { useCallback } from 'react';

import { Directory } from '@core/types/types.common';

import { useBroadcaster } from 'src/shared/hooks/shared.hook.useBroadcaster';
import { DirectoryContext } from 'src/shared/hooks/useDirectory';
import { useRequiredContext } from 'src/shared/hooks/useRequiredContext';

export function useDirectoryUpdater() {
  const broadcast = useBroadcaster();
  const directory = useRequiredContext(DirectoryContext);

  return useCallback(
    (updates: Partial<Directory>) => {
      broadcast({
        event: 'directory:save',
        directory: { ...directory, ...updates },
      });
    },
    [directory],
  );
}
