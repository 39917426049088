import { ReactNode } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Flex, Text } from '@chakra-ui/react';

import { SearchFilterButton } from 'src/Search/Filter/SearchFilterButton';
import { SearchFilterSort } from 'src/Search/Filter/SearchFilterSort';
import { useDirectoryUser } from 'src/shared/hooks/useDirectory';

export interface SearchFilterProps {
  total?: number;
  isLoading: boolean;
}
export function SearchFilter({ total, isLoading }: SearchFilterProps) {
  const [search, setSearch] = useSearchParams();

  const expert = search.get('expert') ?? undefined;

  const user = useDirectoryUser(expert);

  const clearer = (key: string) => {
    search.delete(key);
    setSearch(search);
  };

  const renderedFilters: ReactNode[] = [
    <Text
      fontSize="20px"
      lineHeight="24px"
      letterSpacing="-0.02em"
      color="rgba(3, 52, 38, 0.9)"
      key="first"
    >
      <b>{isLoading ? 'Loading' : total}</b>
      <span style={{ marginLeft: '4px', marginRight: '4px' }}>
        saved answer{total === 1 ? '' : 's'}
      </span>
    </Text>,
  ];

  if (user) {
    renderedFilters.push(
      <SearchFilterButton
        onClick={() => clearer('expert')}
        title={user.name}
        imageUrl={user.image}
        pretext="by"
        key="expert"
      />,
    );
  }

  return (
    <>
      <Flex alignItems="center" mt={8}>
        <Flex alignItems="center" w="100%" flexWrap="wrap">
          {renderedFilters}
        </Flex>
        <SearchFilterSort />
      </Flex>
    </>
  );
}
/* Rectangle 2861 */
