import { useState } from 'react';

import { Accordion, AccordionItem, Text } from '@chakra-ui/react';

import { SearchEmpty } from 'src/Search/Empty/SearchEmpty';
import { SearchEmptyLoading } from 'src/Search/Empty/SearchEmptyLoading';
import { SearchResultLink } from 'src/Search/SearchResultLink';
import { Question } from 'src/shared/components/Question/Question';
import { useESQuestionSearch } from 'src/shared/hooks/useESQuestionSearch';

export function SearchResults({
  res,
}: {
  res: ReturnType<typeof useESQuestionSearch>;
}) {
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const { loading, result } = res;

  const isEmpty = !loading && !result?.answers?.length;
  const isNotEmpty = !loading && !isEmpty;

  const accordions = result?.answers.filter((q) => !!q.raw_answer) ?? [];

  const index = selectedId
    ? accordions.findIndex((q) => q.id === selectedId) ?? -1
    : -1;

  return (
    <>
      {loading && <SearchEmptyLoading />}
      {isEmpty && <SearchEmpty hasFilter />}
      {isNotEmpty && (
        <>
          {result?.generatedAnswer && (
            <>
              <Text
                fontSize="20px"
                lineHeight="24px"
                fontWeight="bold"
                letterSpacing="-0.02em"
                color="rgba(3, 52, 38, 0.9)"
                key="first"
                mt={8}
              >
                AI Answer
              </Text>
              <Text mt={2}>{result.generatedAnswer}</Text>
            </>
          )}
          <Text
            fontSize="20px"
            lineHeight="24px"
            fontWeight="bold"
            letterSpacing="-0.02em"
            color="rgba(3, 52, 38, 0.9)"
            key="first"
            mt={8}
          >
            {result?.generatedAnswer ? 'Sources' : 'Relevant Results'}
          </Text>
          <Accordion
            allowToggle
            mb={4}
            mt={4}
            index={index}
            onChange={(index) => {
              if (typeof index === 'number') {
                setSelectedId(accordions[index]?.raw_answer?.id ?? '');
              }
            }}
          >
            {result?.answers?.map((a) => {
              const question = a.raw_answer;
              if (!question) {
                return <SearchResultLink answer={a} key={a.id} />;
              }
              return (
                <AccordionItem
                  key={question.id}
                  border="none"
                  mt={1.5}
                  data-id={question.id}
                >
                  {({ isExpanded }) => (
                    <>
                      <Question question={question} isExpanded={isExpanded} />
                    </>
                  )}
                </AccordionItem>
              );
            })}
          </Accordion>
        </>
      )}
    </>
  );
}
